import * as React from 'react'
import { closeContactEnrollmentModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalContent from './Parts/ModalContent'
import { useTranslation } from 'react-i18next'
import ModalLoader from './Parts/ModalLoader'
import Button from '../Button/Button'
import { DealStagesController, SequenceEnrollmentsController, SequencesController } from '../../controllers'
import { CurrentUser, DisplayableError, DealStage, DealStageAction, DealStatus, Sequence } from '../../types'
import TooltipError from '../Tooltips/ErrorTooltip'
import PowerSelect from '../Form/PowerSelect'
import ReactSelectTheme from '../Form/ReactSelectTheme'
import Tooltip from '../Tooltips/Tooltip'
import DealHelper from '../../helpers/DealHelper'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'

interface IStateToProps {
  currentUser: CurrentUser
  contactId: string
}

interface IDispatchToProps {
  close: typeof closeContactEnrollmentModal
}

type IProps = IDispatchToProps & IStateToProps

interface IState {
  didInitialLoad: boolean
  errors: DisplayableError[]
  sequenceId: string
  isSubmitting: boolean
}

const ContactEnrollmentModal = (props: IProps) => {
  const { t } = useTranslation()
  const [state, setState] = React.useState<IState>({
    didInitialLoad: false,
    errors: [],
    sequenceId: null,
    isSubmitting: false
  })
  const { contactId } = props
  const { didInitialLoad, errors, sequenceId } = state

  React.useEffect(() => {
    setTimeout(() => {
      setState({ ...state, didInitialLoad: true })
    }, 250)
  }, [])

  const onCloseClick = () => {
    props.close()
  }

  const onFormSubmit = async (e) => {
    e.preventDefault()

    try {
      setState({ ...state, isSubmitting: true })

      await SequencesController.enroll(sequenceId, [contactId])
      props.close()
    } catch (ex) {
      console.error(ex)
    } finally {
      setState({ ...state, isSubmitting: false })
    }
  }

  const onErrorsDismiss = () => {
    setState({ ...state, errors: [] })
  }

  const onSequenceChange = (sequenceId?: string, seuence?: Sequence) => {
    setState({ ...state, sequenceId: sequenceId })
  }

  return (
    <ModalWindow>
      <ModalHeader
        title={t('ContactEnrollmentModal::Enroll contact')}
        onCloseClick={onCloseClick}
      />

      {!didInitialLoad && <ModalLoader />}
      {didInitialLoad && <ModalMiddle>
        <ModalContent>
          <form onSubmit={onFormSubmit}>
            <div className='grid'>
              <div className='grid-cell with-12col'>
                <div className='form-item'>
                  <label>{t('ContactEnrollmentModal::Contact')} <span>*</span></label>
                  <ResourceCreatablePowerSelect
                    type='contact'
                    value={contactId}
                    isDisabled={true}
                  />
                </div>
              </div>

              <div className='grid-cell with-12col'>
                <div className='form-item'>
                  <label>{t('ContactEnrollmentModal::Sequence')} <span>*</span></label>
                  <ResourceCreatablePowerSelect
                    type='sequence'
                    value={sequenceId}
                    onChange={onSequenceChange}
                    isClearable
                  />
                </div>
              </div>
            </div>

            <input type='submit' style={{ display: 'none' }} onClick={onFormSubmit} />
          </form>
        </ModalContent>

        <div className='modal-footer'>
          <div />
          <div key='main-action' className='popover-wrapper'>
            <TooltipError
              errors={errors}
              onDismiss={onErrorsDismiss}
            />
            <Button
              type='success'
              text={t('ContactEnrollmentModal::Enroll')}
              onClick={onFormSubmit}
              disabled={!Boolean(sequenceId)}
            />
          </div>
        </div>
      </ModalMiddle>}
    </ModalWindow >
  )
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser
    },
    modals: {
      contactEnrollmentModal: {
        contactId
      }
    }
  } = state

  return {
    currentUser: currentUser,
    contactId: contactId,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeContactEnrollmentModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactEnrollmentModal)